<template>
  <div class="public-job-board-config mb-4 pb-4">
    <h4 class="text-primary mt-2 mb-4">Public Job Board - Configurations</h4>
    <ValidationObserver ref="advert" v-slot="{ handleSubmit }">
      <form id="advert" @submit.prevent="handleSubmit(onSubmit)">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol lg="6">
                <CRow class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Select Organisation</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="organisation_id"
                        :value="advert.organisation_id"
                        :options="
                          options && options['organisation']
                            ? options['organisation']
                            : []
                        "
                        :multiple="false"
                        :clearable="false"
                        @input="handleChangeSelect"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol
                lg="2"
                v-if="
                  advert.organisation_id && advert.organisation_id.path_acronym
                "
                class="d-flex align-items-center"
              >
                <CBadge
                  color="primary"
                  class="fs-14 mt-3 mb-2"
                  v-c-tooltip="'Path Acronym'"
                  >{{ advert.organisation_id.path_acronym }}</CBadge
                >
              </CCol>
            </CRow>
            <CRow class="row mb-3">
              <label class="col-lg-12 col-md-12"
                >Upload Image
                <small class="text-primary"
                  >(Tip: Upload the image to be shown in the scrolling
                  advertisement section)</small
                ></label
              >
              <div class="col-lg-12 col-md-12 col-sm-12 upload_file">
                <input
                  id="upload-image"
                  name="upload-image"
                  type="file"
                  ref="fileref"
                  :accept="fileType"
                  @change="handleFile"
                />
                <label
                  for="upload-image"
                  class="btn text-white small font-weight-bolder rounded-2"
                  >{{
                    payload && payload.file ? "Select New File" : "Select File"
                  }}</label
                >
                <small class="ml-2" v-if="payload && payload.file"
                  >(file Selected)</small
                >
                <CButton
                  class="btn-primary ml-2 mt-1 small"
                  v-if="payload && payload.file"
                  @click="useCrop()"
                  >Use Crop</CButton
                >
                <small
                  v-if="payload && payload.file"
                  class="text-primary mt-2"
                  style="font-size: 12px; font-weight: 600"
                >
                  Use crop for Larger Images
                </small>
              </div>
              <div v-if="cropperModal.isShowPopup">
                <CropperModal
                  :isShowPopup="cropperModal.isShowPopup"
                  :fileData="cropperModal.fileData"
                  modalColor="primary"
                  :buttonLabel="cropperModal.buttonLabel"
                  @modalCallBack="cropperModalCallBack"
                />
              </div>
            </CRow>
            <CRow>
              <CCol lg="12">
                <CRow class="row mb-3">
                  <CCol lg="12" md="12">
                    <label class="required">URL</label
                    ><small class="text-primary"
                      >(Tip: Provide the web URL or link for navigation if user
                      clicks on the image)</small
                    >
                  </CCol>
                  <div class="col-lg-6 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="link"
                        :value="advert.link"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>

            <CRow class="justify-content-end pr-3 mb-3">
              <label class="btn font-weight-bolder rounded-2">
                Scrolling Adverts
              </label>
              <CSwitch
                color="success"
                :checked="jobAdvertisement"
                shape="pill"
                variant="3d"
                size="sm"
                class="mr-3 mt-1"
                @change.native="handleOffAdvertisement($event)"
              />

              <CButton
                class="btn-secondary mr-3"
                type="button"
                @click="resetForm()"
                >Cancel</CButton
              >
              <CButton class="btn-primary" type="submit">{{
                advert.job_advertisement_id ? `Update` : "Add"
              }}</CButton>
            </CRow>
            <div class="documentScrolling">
              <table class="table">
                <thead>
                  <tr>
                    <th>URL</th>
                    <th>Preview</th>
                    <th>Edit</th>
                    <th>Delete</th>
                    <th>On/Off</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(advert, index) in jobAdvertisements" :key="index">
                    <td>{{ advert.link }}</td>
                    <td>
                      <CButton variant="ghost" @click="preview(advert)">
                        <i class="fas fa-eye"></i>
                      </CButton>
                    </td>
                    <td>
                      <CButton variant="ghost" @click="editAdvert(advert)">
                        <i class="far fa-edit"></i>
                      </CButton>
                    </td>
                    <td>
                      <CButton variant="ghost" @click="deleteAdvert(advert)">
                        <i class="fas fa-trash"></i>
                      </CButton>
                    </td>
                    <td>
                      <CSwitch
                        :key="index"
                        color="success"
                        :checked="advert.is_active"
                        shape="pill"
                        variant="3d"
                        size="sm"
                        @change.native="handleChangeCheck(advert, $event)"
                      />
                    </td>
                  </tr>
                  <tr v-if="!jobAdvertisements.length">
                    <td colspan="5">
                      <p class="text-center text-muted h6 mt-3">
                        No Records Found
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CCardBody>
        </CCard>
        <div class="text-center mx-0 action-area">
          <div class="d-none d-lg-block">
            <CButton
              type="button"
              name="registration-home"
              class="btn rounded-0 px-5 btn-lg btn-outline-primary btn-lg"
              @click="navToCandidateList"
            >
              Home
            </CButton>
          </div>
          <div class="d-lg-none d-block">
            <CButton
              variant="outline"
              size="lg"
              type="button"
              color="primary"
              shape="square"
              class="px-5 m-1 mt-2 w-100"
              @click="navToCandidateList"
            >
              Home
            </CButton>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <CToaster v-if="errorToaster.isError" position="bottom-right">
      <CToast :show="true" class="bg-danger text-white">{{
        errorToaster.message
      }}</CToast>
    </CToaster>
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="previewModalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <Modal
      v-if="deleteModal.isShowPopup"
      :modalTitle="deleteModal.modalTitle"
      :modalColor="deleteModal.modalColor"
      :modalContent="deleteModal.modalContent"
      :isShowPopup="deleteModal.isShowPopup"
      :buttons="deleteModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import CropperModal from "@/components/reusable/CropperModal";
import TextInput from "@/components/reusable/Fields/TextInput";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import PreviewModal from "@/components/reusable/PreviewModal";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
extend("required", { ...required, message: "This field is required" });
import Modal from "@/components/reusable/Modal";
import m from "moment";
import Select from "@/components/reusable/Fields/Select.vue";

export default {
  components: {
    Select,
    CropperModal,
    TextInput,
    PreviewModal,
    Modal,
  },
  data() {
    return {
      advert: {},
      payload: {},
      fileType: "image/png",
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
      },
      errorToaster: {
        isError: false,
        message: null,
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "",
        isShowPopup: false,
        buttons: ["Close"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: true,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      deleteModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "Are you sure to delete this Job Advertisement",
        buttons: ["Cancel", "OK"],
        job_advertisement_id: null,
        isShowPopup: false,
      },
      tempFile: {},
      jobAdvertisement: false,
    };
  },
  computed: {
    ...mapGetters(["jobAdvertisements", "allCustomerOrganisation"]),
    options() {
      return {
        organisation: this.allOrganisationListOpt,
      };
    },
    allOrganisationListOpt() {
      return (
        this.allCustomerOrganisation.map((v) => ({
          code: v?.organisation_id,
          label: v?.organisation?.name,
          customer_id: v?.customer_uid,
          path_acronym: v?.path_acronym,
        })) || []
      );
    },
  },
  methods: {
    ...mapActions([
      "fetchJobAdvertisement",
      "showToast",
      "uploadAdvert",
      "deleteJobAdvert",
      "updateAdvert",
      "fetchAllCustomerOrganisation",
      "getCustomerVariant",
      "updateAdverticementStatus",
      "updateFacilityConfig",
    ]),
    navToCandidateList() {
      this.$router.push({
        path: "/candidate-list?active=%5Btrue,null%5D&deleted=%5Bfalse%5D",
      });
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1];
    },
    handleInput(name, value) {
      Vue.set(this.advert, name, value);
      Vue.set(this.payload, name, value);
    },
    handleFile(event) {
      this.isFileError = false;
      this.isFileTpeValid = false;
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = this.fileType;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.payload = {
              ...this.payload,
              file,
              fileName: m().format("YYYYMMDDHHmmss").concat(`_${file.name}`),
            };
            this.tempFile = file;
          }
        } else {
          const message = "Only png/jpeg allowed";
          this.showToaster({ message });
        }
      } else {
        const message = "File too Big, please select a file less than 2mb";
        this.showToaster({ message });
      }
    },
    useCrop() {
      this.cropperModal.fileData = this.tempFile;
      this.cropperModal.isShowPopup = true;
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        // this.$refs.fileref.value = "";
      } else {
        let { fileBlob, fileName } = data;
        fileName = m().format("YYYYMMDDHHmmss").concat(`_${fileName}`);
        this.payload = { ...this.payload, fileName };
        this.payload.file = fileBlob;
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
      }
    },
    showToaster({ message }) {
      this.$refs.fileref.value = "";
      this.errorToaster.isError = true;
      this.errorToaster.message = message;
      setTimeout(() => {
        this.errorToaster.isError = false;
        this.errorToaster.message = null;
      }, 5000);
    },
    preview(data) {
      this.previewModal.modalTitle = "Image Preview";
      this.iframe.loaded = false;
      this.iframe.ispdf = false;
      this.iframe.isImg = true;
      this.iframe.pdf.ispdf = false;
      this.iframe.src = data.image_path;
      this.previewModal.isPreview = true;
      this.previewModal.isShowPopup = true;
    },
    deleteAdvert(data) {
      const { id } = data;
      this.deleteModal.isShowPopup = true;
      this.deleteModal.job_advertisement_id = id;
    },
    editAdvert(data) {
      const { id: job_advertisement_id, link, is_active } = data;
      this.advert = { ...this.advert, link, job_advertisement_id, is_active };
    },
    previewModalCallBack() {
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
      this.previewModal.isShowPopup = false;
    },
    async onSubmit() {
      const isValid = await this.$refs.advert.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      if (!this.payload.file && !this.advert.job_advertisement_id) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please Choose Image File!",
        });
      } else {
        let appendAction = null;
        if (this.advert?.job_advertisement_id) {
          let finalPayload = {
            link: this.advert?.link,
            job_advertisement_id: this.advert?.job_advertisement_id,
            customer_id: this.advert?.organisation_id?.customer_id,
            organisation_id: this.advert?.organisation_id?.code,
            is_active: this.advert?.is_active,
          };
          if (this.payload?.file)
            finalPayload = {
              ...finalPayload,
              file: this.payload?.file,
              fileName: this.payload?.fileName,
            };
          appendAction = this.updateAdvert(finalPayload);
        } else {
          let finalPayload = {
            link: this.payload?.link,
            file: this.payload?.file,
            fileName: this.payload?.fileName,
            customer_id: this.payload?.customer_id,
            organisation_id: this.payload?.organisation_id,
            is_active: true,
          };
          appendAction = this.uploadAdvert(finalPayload);
        }
        Promise.all([appendAction]).then((res) => {
          const { customer_id, organisation_id } = this.payload;
          this.fetchJobAdvertisement({ customer_id, organisation_id });
          this.resetForm();
        });
      }
    },
    resetForm() {
      this.$refs.fileref.value = "";
      this.advert["link"] = null;
      this.advert["job_advertisement_id"] = null;
      this.payload.file = null;
      this.payload.fileName = null;
      this.tempFile = {};
      this.$nextTick(() => this.$refs.advert.reset());
    },
    deleteModalCallBack(action) {
      if (action === "OK") {
        const { job_advertisement_id } = this.deleteModal;
        this.deleteJobAdvert(job_advertisement_id).then((res) => {
          const { customer_id, organisation_id } = this.payload;
          this.fetchJobAdvertisement({ customer_id, organisation_id });
        });
      }
      this.deleteModal.isShowPopup = false;
    },
    handleChangeSelect(name, value) {
      Vue.set(this.advert, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code : null,
        customer_id: value?.customer_id,
      };
      const { customer_id, code: organisation_id } = value;
      this.customer_id = value.customer_id;
      this.checkAdvertisementStatus();
      this.resetForm();
      this.fetchJobAdvertisement({ customer_id, organisation_id });
    },
    handleChangeCheck(item, $event) {
      const is_active = $event.target.checked;
      const { id: job_advertisement_id, customer_id, organisation_id } = item;
      const payload = {
        job_advertisement_id,
        is_active,
        customer_id,
        organisation_id,
      };
      this.updateAdvert(payload).then((res) => {
        this.fetchJobAdvertisement({ customer_id, organisation_id });
      });
    },
    handleOffAdvertisement($event) {
      const value = $event.target.checked == true ? "TRUE" : "FALSE";
      const customer_id = this.advert?.organisation_id?.customer_id;
      const key = "JOB_ADVERTISEMENT";
      this.updateFacilityConfig({
        key,
        value,
        customer_id: customer_id,
      });
    },
    checkAdvertisementStatus() {
      this.getCustomerVariant(this.customer_id).then((data) => {
        this.jobAdvertisement =
          data?.filter((val) => val.key == "JOB_ADVERTISEMENT")[0].value ==
          "TRUE"
            ? true
            : false;
      });
    },
  },
  mounted() {
    // this.fetchJobAdvertisement();
    this.fetchAllCustomerOrganisation(true).then((res) => {
      if (this.allOrganisationListOpt.length === 1) {
        const first_org = this.allOrganisationListOpt[0];
        this.handleChangeSelect("organisation_id", first_org);
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.fs-14 {
  font-size: 14px;
}
small {
  font-size: 10px;
}
.upload_file #upload-image {
  display: none;
}
.upload_file #upload-image + label {
  display: inline-block;
  text-align: center;
  margin-bottom: 0;
  background: #dd3651;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  width: 110px;
}
.upload_file #upload-image + label:hover {
  background: #ee4763;
}
.upload_file #upload-image + label:after {
  color: #ee4763;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
</style>
